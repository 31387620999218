<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="headers"
      :items="listProducts"
      :loading="isLoadingProducts"
      :server-items-length="productsMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      show-expand
      single-expand
      :footer-props="{
        'items-per-page-options': [5, 30, 50],
        'disable-pagination': isLoadingProducts,
      }"
    >
      <template v-slot:item.image="{ item }">
        <v-img
          v-if="item.image"
          :src="item.image.url"
          max-height="50"
          max-width="50"
        />
        <v-img
          v-else
          src="@/assets/product.png"
          max-height="50"
          max-width="50"
        />
      </template>
      <template v-slot:item.express_status="{ item }">
        <v-chip :color="getColor(item.express_status)" dark>
          {{ item.express_status ? $t("active") : $t("inactive") }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('lot-list')"
          small
          class="mr-2"
          @click="showItemLots(item)"
        >
          mdi-database
        </v-icon>
        <v-icon
          v-if="$admin.can('product-view')"
          small
          class="mr-2"
          @click="showItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          v-if="$admin.hasAny(['product-update', 'product-update-content'])"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('product-delete')"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("products") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <!-- <edit-products v-if="$admin.hasAny(['product-create','product-update','product-update-content'])">
          </edit-products> -->
          <v-btn
            v-if="$admin.can('product-create')"
            color="primary"
            @click="createProduct()"
          >
            {{ $t("add_new_product") }}
          </v-btn>

          <import-products-CSV
            v-if="$admin.can('product-import')"
          ></import-products-CSV>
          <delete-products
            v-if="$admin.can('product-delete')"
          ></delete-products>
          <show-product v-if="$admin.can('product-view')"></show-product>
          <show-lots v-if="$admin.can('product-view')"></show-lots>
          <v-btn
            v-if="$admin.can('product-export')"
            color="primary"
            @click="getCSV(options)"
            :loading="is_loading_csv"
            :disabled="is_loading_csv"
          >
            <v-icon color="white" class="mr-2">
              mdi-arrow-down-bold-circle-outline
            </v-icon>
            {{ $t("export_result_csv") }}
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="options.search"
              :label="$t('search')"
              :disabled="is_loading_product"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-autocomplete
              clearable
              v-model="options.hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_hub"
              :loading="isLoadingHubs"
              no-filter
              :disabled="is_loading_product"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-autocomplete
              clearable
              v-model="options.category_id"
              :items="leafCategories"
              item-text="name"
              item-value="id"
              :loading="isLoadingCategories"
              :label="$t('category')"
              :disabled="is_loading_product"
              @focus="getSubCategories"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-autocomplete
              clearable
              v-model="options.express_status"
              :items="[
                { name: 'active', value: 1 },
                { name: 'inactive', value: 0 },
              ]"
              item-text="name"
              item-value="value"
              :label="$t('express_status')"
              :disabled="is_loading_product"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table class="my-5" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">ID</th>

                  <th class="text-left">
                    {{ $t("name") }}
                  </th>

                  <th class="text-left">
                    {{ $t("status") }}
                  </th>

                  <th class="text-left">
                    {{ $t("quantity") }}
                  </th>
                  <th class="text-left">
                    {{ $t("actions") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="variant in item.variants" :key="variant.id">
                  <td>{{ variant.sku }}</td>
                  <td>{{ variant.name }}</td>
                  <td>
                    <v-chip :color="getColor(variant.status)" dark>
                      {{ variant.status }}
                    </v-chip>
                  </td>
                  <td>{{ variant.qte }}</td>
                  <td>
                    <v-icon small class="mr-2" @click="showItem(variant)">
                      mdi-eye
                    </v-icon>
                    <v-icon small class="mr-2" @click="editItem(variant)">
                      mdi-pencil
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import { helpers } from "@/mixins/helpers";
export default {
  mixins: [helpers],
  components: {
    ShowProduct: () => import("./dialogs/ShowProduct.vue"),
    ShowLots: () => import("./dialogs/ShowLots.vue"),
    DeleteProducts: () => import("./dialogs/DeleteProductsDialog.vue"),
    ImportProductsCSV: () => import("./dialogs/ImportProductsCSV.vue"),
  },

  computed: {
    ...mapGetters({
      isLoadingCategories: "categories/isLoadingCategories",
      isLoadingBrands: "brands/isLoadingBrands",
      isLoadingHubs: "hubs/isLoadingHubs",
      isLoadingSections: "sections/isLoadingSections",
      isLoadingAttributes: "attributes/isLoadingAttributes",

      hubs: "hubs/listHubs",
      brands: "brands/listBrands",
      stores: "stores/listStores",
      leafCategories: "categories/listLeafs",

      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
      productsMeta: "products/meta",
    }),
  },

  watch: {
    options: {
      deep: true,
      handler: debounce(async function () {
        this.is_loading_product = true;
        await this.$store.dispatch("products/root", this.options).then(() => {
          this.is_loading_product = false;
        });
      }, 1000),
    },

    search_hub: debounce(function (search) {
      if (this.isLoadingHubs) return;

      this.$store.dispatch("hubs/list", {
        itemsPerPage: 30,
        search,
      });
    }, 1000),
  },

  data() {
    return {
      options: { itemsPerPage: 30 },
      search_hub: "",
      is_loading_product: false,
      is_loading_csv: false,

      headers: [
        {
          text: this.$t("sku"),
          align: "start",
          sortable: true,
          value: "sku",
        },
        {
          text: this.$t("product_n"),
          align: "start",
          sortable: true,
          value: "code",
        },
        {
          text: this.$t("image"),
          align: "start",
          value: "image",
          sortable: false,
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        // {
        //     text: this.$t("quantity"),
        //     value: "qte"
        // },
        {
          text: this.$t("express_status"),
          value: "express_status",
        },
        {
          text: this.$t("price"),
          value: "price",
          sortable: false,
        },
        {
          text: this.$t("created_at"),
          value: "created_at",
          sortable: true,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    createProduct() {
      this.$router.push({ name: "setup.products.create" });
    },
    showItem(item) {
      this.$router.push({
        name: "setup.products.view",
        params: { id: item.id },
      });
      // this.$store.dispatch("products/openShowForm", item);
    },
    showItemLots(item) {
      this.$store.commit("transferProducts/CURRENT_TAB", "lots");
      this.showItem(item);
    },

    editItem(item) {
      if (item.type === "simple") {
        this.$router.push({
          name: "setup.products.edit",
          params: { id: item.id },
        });
      } else {
        this.$store.dispatch(
          "alerts/error",
          "Updating packages not available yet"
        );
      }

      // this.$store.dispatch("products/openEditForm", item)
    },

    deleteItem(item) {
      this.$store.dispatch("products/openDeleteForm", item);
    },

    getColor(status) {
      if (status === "active" || status == 1) return "green";
      else return "red";
    },

    async getCSV(option) {
      this.is_loading_csv = true;
      option.with_hubs = false;
      await this.$store
        .dispatch("products/downloadCSV", option)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "products.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_csv = false;
        });
    },
  },
};
</script>
